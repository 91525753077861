import { FC } from "react";

import { PaginationProps } from "./types/index";
import { PaginationDetails } from "./components/PaginationDetails";
import { PaginationOptions } from "./components/PaginationOptions";
import { PaginationInfo } from "./components/PaginationInfo";
import { DOTS, usePagination } from "@/components/Table/hooks/usePagination";
import { UsePagination } from "@/components/Table/hooks/usePagination/types";

import "./scss/index.scss";

/**
 * @param onPageChange - Function to update the current page
 * @param totalCount - Total count of current records
 * @param siblingCount - Number of pagination options to display side by side of the current page
 * @param currentPage - Pagination current page
 * @param pageSize - Current size of records to display per page
 * @param pageSizeHandler - Function to update the page size
 * @param pageSizeOptions - Array of available sizes to update the value of the current pagination page size
 * @defaultValue `[20, 50, 100]`
 */
export const Pagination: FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
  pageSizeHandler,
  pageSizeOptions = [20, 50, 100],
}): JSX.Element | null => {
  const paginationRange: UsePagination = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const minOption = Math.min(...pageSizeOptions);

  if (!totalCount) return null;

  // If the number of records in the page is less than the minimum page size options
  // Will render just the number of records
  if (totalCount <= minOption)
    return (
      <div className="pagination-container">
        <div className="pagination-info">
          <span>{totalCount} entries</span>
        </div>
      </div>
    );

  // If there are less than 2 pages in pagination range
  // and the total count is less than the minimum page size option
  // will render the number of records but allow the user to change the page size
  if (!currentPage || (paginationRange.length < 2 && totalCount < minOption))
    return (
      <PaginationInfo
        {...{
          totalCount,
          pageSizeOptions,
          pageSize,
          pageSizeHandler,
        }}
      />
    );

  const onNext = () => onPageChange(currentPage + 1);
  const onPrevious = () => onPageChange(currentPage - 1);
  const onSelect = (value: number) => onPageChange(value);

  const lastPage: number = paginationRange[
    paginationRange.length - 1
  ] as number;

  return (
    <div className="pagination-container">
      <PaginationDetails
        {...{
          currentPage,
          pageSize,
          totalCount,
          pageSizeOptions,
          pageSizeHandler,
        }}
      />

      <PaginationOptions
        {...{
          currentPage,
          onPrevious,
          paginationRange,
          DOTS,
          onPageChange,
          onSelect,
          lastPage,
          onNext,
        }}
      />
    </div>
  );
};

import { useRef, useState } from "react";

import { useReview } from "../useReview";
import { useMailerData } from "../useMailerdata";
import { useVisibility } from "@/hooks/useVisibility";
import {
  FacilityMailerDetails,
  MailerModalProps,
  MailerRecipientsMap,
  RecipientKeys,
  UserMailerDetails,
} from "../../types";
import { getRecipientsDetailsMap } from "./utils/getRecipientsDetailsMap";
import { getDefault } from "./utils/getDefault";
import { getInitialMailerData } from "./utils/getInitialMailerData";
import { MIN_NUMBER_OF_EMAILS } from "../../constants";
import { useGetCurrentUser } from "@root/src/api/useGetCurrentUser";
import { postMailerReview } from "../../api/postMailerReview";
import { sendEmail } from "../../api/sendEmail";

export const useMailerLogic = ({
  bulkConfig,
  config,
  onCloseModal,
  records,
}: MailerModalProps) => {
  //Fetch from the confgs the current recipient type
  const recipient: RecipientKeys | null = bulkConfig
    ? bulkConfig.type
    : config?.type || null;

  const isBulk: boolean = Array.isArray(records);

  //Throw an error if no recipient is selected
  if (recipient === null)
    throw new Error(
      "No recipient selected, please add one in the mailer configs"
    );

  //Get the default values for the mailer
  const { activeRecords, inactiveRecords, superUsers } = getDefault(
    records,
    recipient
  );

  //Error ref
  const errorRef = useRef<number | null>(null);
  const handleBccOnError = (
    selectedEmails: MailerRecipientsMap[RecipientKeys][]
  ) => {
    const numberOfSelectedEmails: number = selectedEmails.length;
    if (numberOfSelectedEmails > MIN_NUMBER_OF_EMAILS)
      errorRef.current = MIN_NUMBER_OF_EMAILS;
    if (errorRef.current && numberOfSelectedEmails < errorRef.current) {
      return "Please select at least one user";
    }

    return undefined;
  };

  //Properties related to the recipient type
  const recipientDetails = getRecipientsDetailsMap({
    recipient,
  });

  //Hook for open/close error alert
  const { visible: displayError, handleVisibility: handleDisplayError } =
    useVisibility({ defaultValue: !!inactiveRecords.length });

  //Hook for fetching current login user
  const {
    full_name,
    email: currentUserEmail,
    is_superuser: isCurrentUserSuperUser,
  } = useGetCurrentUser();
  const currentUser: string = `${full_name} <${currentUserEmail}>`;

  //Ref for storing sending a copy or not to current login user
  const [sendCopy, setSendCopy] = useState<boolean>(false);
  const handleSendCopy = (value: boolean) => setSendCopy(value);

  //Mailer data
  const { mailerData, handleMailerData, ...mailerState } = useMailerData({
    ...getInitialMailerData(activeRecords, recipient),
    superUsers,
    recipient,
  });

  const mailerDataMap = <T extends RecipientKeys>(
    recipient: T,
    records: MailerRecipientsMap[T][]
  ) => {
    const recipientLogicMap: {
      [Key in RecipientKeys]: (records: MailerRecipientsMap[Key][]) => void;
    } = {
      user: (records) => handleMailerData("email_addresses", records),
      facility: (records) => handleMailerData("facility_uuids", records),
    };

    return recipientLogicMap[recipient](records);
  };

  const getMailerDataMap = <T extends RecipientKeys | "cc">(recipient: T) => {
    const recipientMap = {
      user: mailerData.email_addresses,
      facility: mailerData.facility_uuids,
      cc: mailerData.cc,
    };

    return recipientMap[recipient];
  };

  //Hook for fetching review page
  const { review, reviewHandler } = useReview();
  const submitReview = () => postMailerReview({ data, reviewHandler });

  //Mailer data to be sent
  const data = {
    ...mailerData,
    email_addresses: mailerData.email_addresses.map(({ email }) => email) || [],
    facility_uuids: mailerData.facility_uuids.map(({ id }) => id) || [],
    cc_email_addresses: mailerData.cc.map(({ email }) => email) || [],
    send_copy: sendCopy,
  };

  //Send email and close modal handler
  const submitEmail = () =>
    sendEmail({
      data,
      onCloseModal,
    });

  //Error message based on the number of inactive records
  const errorMessage: string = isBulk
    ? `The following items couldn't be added: ${inactiveRecords}`
    : `The following item couldn't be added ${inactiveRecords}`;

  //Get recipient details for bcc
  const userEmails: UserMailerDetails[] = mailerData.email_addresses || [];
  const facilityNames: FacilityMailerDetails[] =
    mailerData.facility_uuids || [];

  const getBccContent = (recipient: RecipientKeys): string => {
    const bccContentMap = {
      user: recipientDetails.bccContent(userEmails),
      facility: recipientDetails.bccContent(facilityNames),
    };

    return bccContentMap[recipient];
  };

  //Get recipient details for cc
  const ccRecipientDetails = getRecipientsDetailsMap({
    recipient: "user",
  });
  const ccUrl = `${ccRecipientDetails.suggestionsUrl}?is_superuser=true`;

  //Get bcc helper text
  const bccHelperText = () => {
    let currentRecipient = "";
    if (recipient === "user") currentRecipient = "users";
    if (recipient === "facility") currentRecipient = "facilities";

    return `You can add multiple ${currentRecipient}`;
  };

  return {
    recipient,
    recipientDetails,
    displayError,
    handleDisplayError,
    reviewHandler,
    isBulk,
    getMailerDataMap,
    ccUrl,
    currentUser,
    sendCopy,
    ccRecipientDetails,
    handleSendCopy,
    bccHelperText: bccHelperText(),
    mailerData,
    handleBccOnError,
    handleMailerData,
    mailerDataMap,
    superUsers,
    getBccContent,
    activeRecords,
    mailerState,
    review,
    submitReview,
    submitEmail,
    errorMessage,
    currentUserEmail,
    isCurrentUserSuperUser,
  };
};
